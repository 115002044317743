.indicatorContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.indicator {
  height: 15px;
  width: 15px;
  border-radius: 15px;
}
