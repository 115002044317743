.item {
  margin-left: 5%;
  cursor: default;
  color: black;
  font-size: smaller;
  font-size: medium;
}
.dashboardHeaderItem {
  align-items: center;
  display: flex;
  flex-direction: row;
  padding-left: 5%;
  margin: 10px 5%;
}
.dashboardItem {
  text-decoration: none;
  align-items: center;
  display: flex;
  flex-direction: row;
  padding-left: 12%;
  margin: 10px 5%;
  padding-bottom: 15px;
  padding-top: 15px;

  * {
    cursor: pointer;
  }
}
