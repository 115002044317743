.unlockContainer, .lockContainer, .alarmContainer {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.stationModalContainer{
  align-self: center;
  padding: 15px 20px 15px 0;
  overflow-x: none;
  overflow-y: scroll;
}

.stationModalContainer::-webkit-scrollbar {
  -webkit-appearance: none;
}
.stationModalContainer::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 1px solid white;
  background-color: rgba(0, 0, 0, .5);
}
.stationModalContainer::-webkit-scrollbar:vertical {
  width: 8px;
}
