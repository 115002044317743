.bikeHistoryContainer {
  margin: 2rem;
}

.bikeHistoryTitle {
  font-weight: bold;
  font-size: 2rem;
  margin: 0;
}

.bikeHistoryHeader {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 2rem;
}

.bikeHistoryTimeFilter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 60%;
  margin: 2rem 0;
}

.bikeHistoryDateInput {
  width: 40%;
}

.bikeHistoryButton{
  background-color: '#419aff';
}

.noBikeHistory{
  min-height: 55vh;
  display: flex;
  justify-content: center;
  align-items: center;
}