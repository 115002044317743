.clientId {
  text-align: center;
  width: 5%;
  height: 40px
}
.clientLastName {
  text-align: center;
  width: 10%;
}
.clientFirstName {
  width: 10%;
  text-align: center;
}
.clientAddress {
  width: 15%;
  text-align: center;
}
.clientEmail {
  width: 17%;
  text-align: center;
}
.clientPhone {
  width: 13%;
  text-align: center;
}
.clientPostCode {
  width: 5%;
  text-align: center;
}
.clientEmailVerified {
  width: 10%;
  text-align: center;
}
.clientButtonWidth {
  width: 10%;
}
.userLink {
  cursor: pointer;
}