.popover {
  z-index: 2;
}
.cover {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.changeColorContainer {
  width: 20px;
  height: 20px;
  margin-left: 15px;
  border-width: 1px;
  border-color: lightslategray;
  padding: 2px;
  border-style: solid;
  position: relative;
  top: -5px;
  right: 0;
}
.cityInputBlock {
  align-self: center;
  overflow-y: scroll;
}
.cityInputBlock::-webkit-scrollbar {
  -webkit-appearance: none;
} 
.cityInputBlock::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 1px solid white;
  background-color: rgba(0, 0, 0, .5);
}
.cityInputBlock::-webkit-scrollbar:vertical {
  width: 8px;
}
.cityInputWithColor {
  display: flex;
  flex-direction: row;
  width: 95%;
  align-items: center;
  justify-content: space-between;
}
.colorInput {
  width: 90%;
}
.cityEditBlock {
  display: flex;
  flex-direction: row;
}
.cityColorPickerBlock {
  align-self: center;
  justify-content: center;
  margin-left: 5%;
}

.dropzone{
  width: 95%;
  height: 250px;
  border: 1px dashed black;
  margin-bottom: 10px;
  text-align: center;
  cursor: pointer;
}

.image{
  width: 100%;
  height: 200px;
  // object-fit: cover;
}

