#tooltip
{
  position: relative;
  font-size: 12px;
  border: 0.01px solid gray;
  right: 14px;
}

#tooltip.top
{
  top: -43px;
}

#tooltip.top .tooltip-arrow
{
  top: auto;
  bottom: -5px;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: gray;
  
}

#tooltip .tooltip-arrow
{
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-right-color: transparent;
  border-style: solid;
}

#tooltip .tooltip-label
{
  max-width: 200px;
  padding: 3px 8px;
  color: #000;
  text-align: center;
  background-color: #fff;
  border-radius: 4px;
}
