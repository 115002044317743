.searchBlockContainer {
  width: 60%;
  display: flex;
  justify-content: flex-end;
}
.dashboardTitleContainer {
  margin: 2.01%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  z-index: 100;
  position: sticky;
}

.filterDescriptionBloc {
  display: flex;
  margin: -5px 10px 0 0;
  justify-content: flex-end;
  align-items: center;
}
