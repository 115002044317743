.mapFilter {
  position: relative;
  width: 250px;

  .mapFilterFormGroup {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px;
  }

  .mapFilterLocks {
    display: flex;
    justify-content: center;
    gap: 20px;
  }

  .mapFilterTitle {
    margin-bottom: 5px;
  }

  .mapFilterSection {
    margin-bottom: 10px;
  }

  .mapFilterBottom {
    display: flex;
    justify-content: center;
  }

  .mapFilterBatteryLevel {
    padding: 0 10px;
  }
}
