.app {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10%;
}
.registerModalBlock {
  height: 30%;
}
.mobileApp {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10%;
}

.inputBlock {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.textDescription {
  font-size: 20px;
  width: 100px;
}

.input {
  margin-top: 16px;
  margin-bottom: 16px;
  font-size: 2vh;
  width: 22vh;
  border-color: black;
  border-width: 1px;
  border-radius: 8px;
  padding: 5px 5px;
}

.button {
  display: flex;
  margin-top: 16px;
  width: 100%;
  height: 40px;
  font-size: 20px;
  align-items: center;
  justify-content: center;
  box-shadow:0 1px 2px #5e5d5b;
}

.link {
  display: flex;
  width: 100%;
  font-size: 12px;
  align-items: center;
  justify-content: center;
}

.authBlock {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.message {
  margin-top: 5px;
  margin-bottom: 5px;
  color: red;
}
.sentEmailContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.centerBold {
  text-align: center;
  font-weight: bold;
}
