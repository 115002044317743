.stationModalButtonsContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
  margin-top: 20px;
}
.stationModalActionButton {
  width: 40%;
}
.stationModalStatusButton {
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
}
.stationModalContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-self: center;
  overflow-y: scroll;
}

.stationModalContainer::-webkit-scrollbar {
  -webkit-appearance: none;
}
.stationModalContainer::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 1px solid white;
  background-color: rgba(0, 0, 0, .5);
}
.stationModalContainer::-webkit-scrollbar:vertical {
  width: 8px;
}

.mapContainer {
  width: 50%;
  margin-left: 2.5%;
}
.stationFieldsHalf {
  width: 50%;
}
.stationFieldsFull {
  width: 100%;
}
.stationModalButton {
  width: 25%;
}
.checkbox-stations {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 20px;
}
.checkbox-input {
  display: flex;
  flex-shrink: 0;
  width: 220px;
}
.title-checkbox {
  margin-right: 2%;
}

