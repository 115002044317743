.couponContainer{
  background-color: rgba(237, 49, 89, 0.05);
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 15px;
  position: relative;
}
.couponName{
  font-weight: 500;
  font-size: 17px;
  color: rgba(237, 49, 89, 1);
}
.couponDescription{
  font-size: 25px;
  color: rgba(3, 3, 3, 1);
}
.couponExpireDate{
  color: rgba(125, 125, 125, 1);
}
.crossIcon{
  position: absolute;
  right: 0;
  top: 0;
}
