.base-background {
  background-color: #ffffff;
  //padding: 50px;
}
.divider {
  border-bottom: 1px solid #e0e0e0;
  width: 98%;
  margin: 0 auto;
}

.tabs {
  .tab-header {
    height: 50px;
    display: flex;
    align-items: center;
    margin-top: 20px;
  }
  div {
    width: calc(100%/3);
    text-align: center;
    cursor: pointer;
    color: #888;
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    outline: none;
  }
  div.active {
    color: #00acee;
  }
  .tab-indicator {
    position:relative;
    width: calc(100%/8);
    height: 7px;
    background: #00acee;
    left:0px;
    border-radius: 5px;
    transition: all 500ms ease-in-out;
  }
  .tab-body{

    position: relative;
    height: calc(100% - 60px);
    padding:10px 5px;

    div {
      position: absolute;
      top:-200%;
      opacity: 0;
      margin-top:5px;
      transform : scale(0.9);
      transition: opacity 500ms ease-in-out 0ms,
      transform 500ms ease-in-out 0ms;
    }

    div.active {
      top: 0;
      opacity: 1;
      transform : scale(1);
      margin-top: 0;
    }

  }
}
.disabled-tab {
    background-color: #fafafa;
    pointer-events: none;
    opacity: 0.5;
}

.initialTab {
  width: 100px;
}
.border{
    border: 1px solid #98A2B3;
    border-radius: 5px;
    padding: 8px
}

.parent {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 0px;
}
.div1 { grid-area: 1 / 1 / 2 / 2; }
.div2 { grid-area: 1 / 2 / 2 / 3; }
.div3 { grid-area: 1 / 3 / 2 / 6; }
.div4 { grid-area: 1 / 6 / 2 / 7; }

.w-100 {
  width: 100%;
}

.pricing-plan-heading {
  padding: 50px 50px 0 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}


