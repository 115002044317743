.custom-virtual-station-marker {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  .custom-virtual-station-marker__icon {
    scale: 0.6;
  }
}
.markerPopupCustomImage {
  z-index: 1;
  position: relative;
  display: flex;
  cursor: pointer;
  width: 400px;
  background-color: white;
  flex-direction: column;
  padding: 10px;
  align-items: stretch;
  justify-content: space-around;
  margin-left: 15px;
}
.markerPopupCustomImageAnimated {
  z-index: 1;
  position: relative;
  display: flex;
  cursor: pointer;
  background-color: white;
  flex-direction: column;
  padding: 10px;
  align-items: stretch;
  justify-content: space-around;
  margin-left: 15px;
  animation-name: markerPopupCustomImage;
  animation-duration: 0.3s;
  width: 400px;
}

// Animated box with width 400px

@keyframes markerPopupCustomImage {
  0% {
    opacity: 0;
    transform: translateY(-10px);
    width: 0;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
    width: 400px;
  }
}
