.colorContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 40px
}
.cityName {
  text-align: center;
  width: 7%;
}
.colorBox {
  margin-left: 4%;
  width: 20px;
  height: 20px;
}

.colorEmptyContainer {
  text-align: center;
  width: 10%;
}
.createCity {
  width: 10%;
}
.createCityContainer {
  width: 95%;
  display: flex;
  justify-content: flex-end;
}
.cityStatus {
  text-align: center;
  width: 7%;
  margin-right: 5%;
}
.cityLink {
  cursor: pointer;
}