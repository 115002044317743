* {
    margin:0;
    padding:0;
}

$pdtop: 20px;

.header {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 20px;

}
.header .title {
    font-size: 25px;
    font-weight: 600;
}

.iconButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 44px;
    border-radius: 11px;
    background-color: #f2f2f2;
    cursor: pointer;
}

.refresh {
//    shadow
//    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
//    width: 200px;
//    height: 40px;
//    border-radius: 20px;
//    background-color: #f2f2f2;
//    display: flex;
//    align-items: center;
//    justify-content: center;
//    cursor: pointer;
//    transition: all 0.3s ease;
//    &:hover {
//        background-color: #e6e6e6;
//    }
}
.gray-color-divider{
    background-color: #f2f2f2;
    height: 1px;
    width: 100%;
    margin: 20px 0;
}

.divider {
    background-color: #f2f2f2;
    height: 1px;
    width: 100%;
    margin: 20px 0;
}

.main-container{
    display: flex;
    width: 100%;
    height: 100%;
    padding: 0 20px;
    margin-top: 20px;

    .table-container{

        width: 64%;
        .table-header{
            p {

                font-size: 20px;
                font-weight: 500;
                color: #000000;
                margin-bottom: 70px;
            }
        }
    }
}

.calendar-filter{
    //popfromtop animation
    display: flex;
    align-items: center;
    justify-content: center;
    animation: popfromtop 0.3s ease;
    border:1px solid #f1f1f1;
    padding:20px;
    @keyframes popfromtop {
        0% {
            opacity: 0;
            transform: translateY(-20px);
        }
        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }
}

.user-card{

    width: 27%;
    margin-left: 4%;

    //animation pop from right


    .user-card-header{
        margin-bottom: 70px;
        p {
            font-size: 20px;
            font-weight: 500;
        }

    }
    .user-info-card {
        box-shadow: 0px 1px 37px 10px rgba(130,130,130,0.83);
        -webkit-box-shadow: 0px 1px 37px 10px rgba(130,130,130,0.83);
        -moz-box-shadow: 0px 1px 37px 10px rgba(130,130,130,0.83);
        width: 100%;
        background-color: #ffffff;
        border-radius: 10px;
        padding:$pdtop;

        animation: popFromRight 0.5s ease;
        @keyframes popFromRight {
            0% {
                transform: translateX(100%);
            }
            100% {
                transform: translateX(0);
            }
        }


        .user-info-card-header {

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding-top: $pdtop;

            .header-image{
                .header-img{
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    margin-right: 10px;
                }
            }
            .user-name{
                font-size: 20px;
                font-weight: 600;
            }
            .user-date{
                font-size: 14px;
                font-weight: 400;
                color: #8a8991;
            }
        }

        .user-pricing-info{
            padding-top: $pdtop;

            text-align: center;
            .amount-title{
                font-size: 20px;
                font-weight: 600;
                color: #9896a1;
            }
            .user-current-balance{
                font-size: 24px;
                font-weight: 600;
                h1 {
                    color: #5988a2;
                }
            }
            .user-current-due{
                font-size: 24px;
                font-weight: 600;
                h1 {
                    color: #f53e3e;
                }
            }
            .user-pricing-info{
                font-size: 14px;
                font-weight: 600;
            }
        }

        .user-plan-info{
            padding-top: $pdtop;
            text-align: center;
            .plan-header{
                p {
                    font-size: 20px;
                    font-weight: 600;
                    color: #9896a1;
                }
            }
            .print-pricing-plan{
                height: 100px;
                background: lightgray;
            }
        }

        .final-sections{
            padding-top: $pdtop;
            display:flex;
            flex-direction: row;
            justify-content: space-around;

            .subtotal-amount{
                font-size: 20px;
                font-weight: 600;
            }
            .tax-amount{
                font-size: 20px;
                font-weight: 600;
            }
            .amount-due{
                .total-amount{
                    font-size: 20px;
                    font-weight: 600;
                }
            }
        }
    }

}
.adjust-balance{
    float: right;
}


