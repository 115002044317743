.notFoundContainer {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notFoundTitle {
  font-size: 32px;
  font-weight: bold;
}