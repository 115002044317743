.rdt_Table{}
.rdt_TableRow {
  //cursor: pointer;
}
.rdt_TableRow:nth-child(even) {
  background: #f4f4f4;
}
//rdt_TableRow animated hover
.rdt_TableRow:hover {
  //background: #f4f4f4;
  transition: background 0.3s ease;
}
.rdt_TableCol {}
.rdt_TableCol_Sortable {}
.rdt_TableCell {
  //border-right: 1px solid #e9e9e9;
  min-height: 55px;
}
.rdt_TableCell:last-child {
  border-right: none;
}
.rdt_TableHeader {}
.rdt_TableFooter {}
.rdt_TableHead {}
.rdt_TableHeadRow {}
.rdt_TableBody {}
.rdt_ExpanderRow {}
