.custom-tabs{
  display: flex;
  border-bottom: 5px solid #3F50B5;
}
.custom-tab{
    display: flex;
    height: 70px;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 18px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    cursor: pointer;
    background: #f1f1f3;
    width:20%;
    margin-right: 20px;
}
.custom-tab.active{
    background: #3F50B5;
    color: white;
    //animation fade background from white to #1fbab8
    animation: fade 0.5s;
}

.stationItem {
    cursor: pointer;
}
.stationId {
    text-align: center;
    width: 5%;
    cursor: default;
    height: 40px
}
.company {
    text-align: center;
    width: 10%;
    cursor: default;
}
.stationCode {
    text-align: center;
    width: 10%;
    cursor: default;
}
.numberOfPlaces {
    text-align: center;
    width: 5%;
    cursor: default;
}
.status {
    text-align: center;
    width: 15%;
    cursor: default;
}
.stationLink {
    cursor: pointer;
}
.menu {
    width: 100%;
}