@use "sass:math";
.elivated-card {
  background: #FAFAFA;
  /* M3/Elevation Light/2 */

  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
  border-radius: 12px;

  padding:20px;
  width: math.div(1,3.3)
}

.divider-new {
  width: '80%';

  /* M3/sys/light/outline-variant */

  border: 1px solid #CAC4D0;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}


.header-text-icon {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
