[data-column-id="sortable1"].rdt_TableCol {
  font-weight: bold;
}
[data-column-id="sortable2"].rdt_TableCol {
  font-weight: bold;
}
[data-column-id="sortable3"].rdt_TableCol {
  font-weight: bold;
}
[data-column-id="sortable4"].rdt_TableCol {
  font-weight: bold;
}
[data-column-id="sortable5"].rdt_TableCol {
  font-weight: bold;;
}
[data-column-id="sortable6"].rdt_TableCol {
  font-weight: bold;
}
body .eUeqdG , body .bMgaAx div:first-child{
  white-space: inherit;
  text-align: center;
}

body .inzVC{
  min-width: 100px;
}







