.ant-tag {
  border-radius: 0;
  border: 0;
  background-color: #f5f5f5;
  color: #000;
  font-size: 12px;
  font-weight: 400;
  padding: 0 8px;
  margin: 0 4px 8px 0;
  height: 24px;
  line-height: 24px;
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    background-color: #e8e8e8;
  }
}
.ant-tag-red {
    background: #fff1f0;
    color: #cf1322;
  border-color: #ffa39e;
    &:hover {
        background-color: #eb2f2f;
    }
}

.red-tag {
  direction: initial;
  box-sizing: border-box;
  padding: 0;
  font-size: 12px;
  line-height: 20px;
  list-style: none;
  font-family: -apple-system,BlinkMacSystemFont,segoe ui,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji;
  display: inline-block;
  height: auto;
  margin-inline-end: 8px;
  padding-inline: 7px;
  white-space: nowrap;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  opacity: 1;
  transition: all .2s;
  text-align: start;
  color: #cf1322;
  background: #fff1f0;
  border-color: #ffa39e;
  margin: 0 0 8px;
}
.magenta-tag{
  direction: initial;
  box-sizing: border-box;
  padding: 0;
  font-size: 12px;
  line-height: 20px;
  list-style: none;
  font-family: -apple-system,BlinkMacSystemFont,segoe ui,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji;
  display: inline-block;
  height: auto;
  margin-inline-end: 8px;
  padding-inline: 7px;
  white-space: nowrap;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  opacity: 1;
  transition: all .2s;
  text-align: start;
  color: #c41d7f;
  background: #fff0f6;
  border-color: #ffadd2;
  margin: 0 0 8px;
}
.green-tag {
  direction: initial;
  box-sizing: border-box;
  padding: 0;
  font-size: 12px;
  line-height: 20px;
  list-style: none;
  font-family: -apple-system,BlinkMacSystemFont,segoe ui,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji;
  display: inline-block;
  height: auto;
  margin-inline-end: 8px;
  padding-inline: 7px;
  white-space: nowrap;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  opacity: 1;
  transition: all .2s;
  text-align: start;
  color: #389e0d;
  background: #f6ffed;
  border-color: #b7eb8f;
  margin: 0 0 8px;
}
