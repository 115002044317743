.searchBlockContainer {
  width: 98%;
  display: flex;
  justify-content: flex-end;
}
.dashboardTitleContainer {
  margin: 2% 10px 0 5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

.filterDescriptionBloc {
  display: flex;
  margin: -5px 10px 0 0;
  justify-content: flex-end;
  align-items: center;
}
