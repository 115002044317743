.settings-container {
  padding: 30px;
}
.divider-gray {
  margin: 30px 0;
  border-bottom: 1px solid #e0e0e0;
}

.wallet-text {
    font-size: 20px;
    font-weight: 500;
    color: #000000;
    margin-bottom: 10px;
}
