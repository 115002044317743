a {
  font-size: 16px;
}
.emptyResultText {
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  margin-top: 20%;
  margin-bottom: 20%;
}
.selectedTab {
  background-color: #ddd;
  justify-content: center;
  align-items: center;
}
.tab {
  flex: 1;
  text-align: center;
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;
}
.generalTabs {
  display: flex;
  width: 100%;
  flex: 1;
  flex-direction: row;
  align-items: stretch;
  border: '0';
  margin: 0;
  padding: 0;
}
