.modalClickedBlockContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  margin-bottom: 10px;
}
.descriptionModal {
  margin-right: 5%;
  width: 15%;
}
.clickedBlockButton {
  width: 80%;
}
