.tooltip-sample{
  background: white;
  border: 1px solid #ccc;
  padding: 10px;
  border-bottom: none;
}

.tooltip-sample::after{
    //content: "";
    //position: absolute;
    //top: 100%;
    //left: 50%;
    //margin-left: -25px;
    //border-width: 30px;
    //border-style: solid;
    //border-color: #ccc transparent transparent transparent;
}
