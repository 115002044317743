.filterItemContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.filterInputLabel {
  width: 20%;
  margin-left: 5%;
}

.filterSelector {
  width: 60%;
  margin-right: 10%;
}
