.dockCode {
  text-align: center;
  width: 10%;
  height: 40px
}
.dockStatus {
  text-align: center;
  width: 10%;
}
.buttonDockContainer {
  text-align: center;
  width: 10%;
}
