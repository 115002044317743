.dashboard {
  display: flex;
  height: calc(100vh - 64px);
  width: 100%;
  background: whitesmoke;
}
.dashboardMenu {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  overflow: auto;
  width: 15%;
  background: white;
}
.dashboardContainer {
  margin: 20px;
  flex-grow: 1;
  display: flex;
  background: white;
  flex-direction: column;
  overflow: auto;
}

.dashboardContent {
  width: 100%;
  height: 100%;
  flex-direction: column;
}
.dashboardList {
  margin: 10px;
}
@media (min-width: 1100px) {
  .clientSearch {
    margin-top: 8px;
    font-size: 12px;
    width: 200px;
    border-color: gray;
    border-width: 1px;
    padding: 15px 5px;
    outline: none;
  }
  .dashboardTitle {
    font-size: 15px;
    font-weight: bold;
  }
  .dashboardItemContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 10px;
    margin-top: 1%;
    margin-bottom: 1%;
  }
}
@media (min-width: 1500px) {
  .clientSearch {
    margin-top: 16px;
    margin-bottom: 16px;
    font-size: 20px;
    width: 400px;
    border-color: gray;
    border-width: 1px;
    padding: 15px 10px;
    outline: none;
  }
  .dashboardTitle {
    font-size: 25px;
    font-weight: bold;
  }
  .dashboardItemContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px 1%;
  }
}

.dashboardItemSeparator {
  background: #dddddd;
  width: 98%;
  height: 1px;
  margin-left: 1%;
}
.dashboardProgress {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}
