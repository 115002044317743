.modalContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    height: 60px;
    background-color: #fff;
    border-bottom: 1px solid #ebebeb;
    margin-bottom: 20px;
}
.modalTitle {
    font-size: 22px;
    font-weight: 700;
    line-height: 1.2;
    color: #000;
}
