.loaderDashboardContent {
  width: 100%;
  height: 100%;
  flex-direction: column;
}
.loaderDashboardProgress {
  background-color: #f5f5f5;
  display: flex;
  min-height: 80vh;
  margin-top: auto;
  justify-content: center;
  align-items: center;
}

#right-wheel,
#left-wheel {
  animation: wheel 1s infinite linear;
  transform-origin: center;
  transform-box: fill-box;
}

#bikeman {
  animation: bike 1s ease-in-out infinite alternate;
  transform-origin: bottom;
}

/* #hat{
    animation: hat 1s ease-in-out infinite alternate;
    transform-origin: center;
    transform-box: fill-box;
} */

@keyframes wheel {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
  }
}

@keyframes bike {
  from {
    transform: rotateX(0deg);
  }
  to {
    transform: rotateX(20deg);
  }
}

@keyframes hat {
  from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(40%);
  }
}
