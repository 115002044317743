input[type='checkbox'] {
  -moz-appearance: none;
  display: inline-block;
  width: 20px;
  height: 20px;
  padding: 6px;
  border: 1.5px solid #bbbbbb;
  border-radius: 6px;
  background-color: #e7e6e7;
  margin-right: 5px;
  vertical-align: middle;
  &:checked {
    background-color: #ff0000;
  }

  &:focus {
    outline: none !important;
  }
}

input {
  margin-right: 5px;
}

.containerMapV2 {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.filterContainer {
  border-bottom: 1px solid gray;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
}

.filter {
  display: flex;
  margin-left: 5px;
  margin-right: 5px;
}

.filterDescription {
  color: gray;
  font-size: 11px;
  position: absolute;
  top: 0px;
}

.icon {
  height: 100%;
  width: 100%;
}
.bikeTypeCheckBoxContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 15px;
}
.bikeTypeCheckBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 10px;
}
.bikeTypeCheckBoxImageContainer {
  width: 31px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
}
.batteryContainer {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 10px 0 10px 20px;
}

.bikesMarkerContainer {
  width: 25px;
  height: 25px;
  cursor: pointer;
  position: absolute;
  transform: translate(-50%, -50%);
}

.markerContainer {
  display: flex;
  width: 25px;
  height: 25px;
  border-radius: 25px;
  background-color: white;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  transform: translate(-50%, -50%);
}

.markerPopup {
  z-index: 1;
  position: relative;
  display: flex;
  cursor: pointer;
  width: 400px;
  background-color: white;
  flex-direction: column;
  padding: 10px;
  align-items: stretch;
  justify-content: space-around;
  margin-left: 15px;
}

.markerPopupButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.markerPopupButtonsContainer {
  margin: 10px;
}

.emptyContent {
  width: 0px;
  height: 0px;
}

.teste {
  width: 100%;
  height: 500px;
  background-color: grey;
}

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: red;
}

.dropFilter {
  margin: 10px;
  width: 30%;
}

.colorOfItem {
  margin-right: 5px;
  width: 10px;
  height: 10px;
}

.itemInFilter {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.eachFilter {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
}

#red {
  border: 2px solid red;
}

#green {
  border: 2px solid green;
}

#lightGreen {
  border: 2px solid lightgreen;
}

#blue {
  border: 2px solid blue;
}

#orange {
  border: 2px solid orange;
}

#yellow {
  border: 2px solid yellow;
}

#blue {
  border: 2px solid blue;
}

.cluster {
  width: 25px;
  height: 25px;
  cursor: pointer;
  border: 1px solid gray;
  display: flex;
  border-radius: 25px;
  align-items: center;
  justify-content: center;
  background-color: #bfe2ff;
  position: absolute;
  transform: translate(-50%, -50%);
  font-weight: bold;
  z-index: 1;
}
.title-container {
  display: flex;
  .main-title {
    margin-bottom: 2%;
  }
  .sub-title {
  }
}
.filter-container {
  display: flex;
  z-index: 1;
  position: absolute;
  top: 10px;
  left: 10px;
}

.filter-container-main {
  z-index: 1;
  position: absolute;
  width: 422px;
  top: 70px;
  left: 10px;
  flex-direction: column;
}
.search-container {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  margin-left: 2%;
  padding: 6px;
  border-radius: 10px;
  // width: 380px;
  max-height: 360px;
  // margin-top: 1%;
  //  animation form width 0 to 200
  //    animation: form 0.5s;
  //    @keyframes form {
  //        from {
  //        width: 0%;
  //        }
  //        to {
  //        width: 100%;
  //        }
  //    }
  .search-input {
    border: none;
    outline: none;
    width: 100%;
    margin-left: 5px;
  }
  .search-icon {
    color: #000000;
  }
}
.search-container .MuiPaper-elevation1 {
  box-shadow: none;
}
.search-container .MuiTableContainer-root {
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-gutter: stable both-edges;
}

.all-filter-container {
  display: flex;
  background-color: #ffffff;
  margin-left: 1%;
  padding: 10px;
  border-radius: 10px;
  margin-top: 10px;
  flex-direction: column;
  //  animation form width 0 to 200
  // animation: form 0.5s;
  // @keyframes form {
  //   from {
  //     width: 0%;
  //   }
  //   to {
  //     width: 100%;
  //   }
  // }
}
.filter-top-section {
  display: flex;
  flex-direction: column;
  padding: 0 10px 0 10px;
}
.divider {
  border: 1px solid #000000;
  margin: 0 5px;
  width: 100%;
}

.filter-bottom-section {
  display: flex;
  padding: 4px 22px 0px 22px;
  flex-direction: column;
  align-items: center;
}
.mapPageTable {
  overflow: hidden;
}
.mapPageTable .MuiTableCell-sizeSmall {
  padding: 0px;
}
.mapPageTable .MuiTableCell-root {
  padding: 8px 2px 8px 2px;
  border-bottom: 0px;
}

.mapPageTable td p {
  text-align: left;
}

.mapPageTable tbody td p {
  font-weight: 500;
}
.mapPageTable tbody td:last-child {
  width: 100% !important;
  display: flex;
  justify-content: center;
}
.mapPageTable tbody td:last-child div {
  max-width: 100%;
  min-width: 50%;
  border-radius: 15px !important;
}

.MuiTableRow-root td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.MuiTableRow-root td:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

/* Hide the native checkbox */
.native-checkbox {
  display: none;
}

/* Style the Mui checkbox container */
.mui-checkbox {
  display: inline-block;
  position: relative;
  cursor: pointer;
}

/* Style the Mui checkbox icon */
.mui-checkbox-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #888;
  background-color: #fff;
}

/* Style the Mui checkbox icon when checked */
.mui-checkbox input:checked + .mui-checkbox-icon {
  background-color: #2196f3;
  border-color: #2196f3;
}

/* Style the Mui checkbox icon::before element */
.mui-checkbox-icon::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 4px;
  transform: translateY(-50%);
  width: 8px;
  height: 4px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

/* Show the Mui checkbox icon::before element when checked */
.mui-checkbox input:checked + .mui-checkbox-icon::before {
  opacity: 1;
}
