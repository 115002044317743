.stripIdTransaction {
  text-align: center;
  width: 5%;
  height: 40px
}
.userIdTransaction {
  text-align: center;
  width: 5%;
}
.purchaseTransaction {
  text-align: center;
  width: 10%;
}
.amountTransaction {
  text-align: center;
  width: 10%;
}
.dateTransaction {
  text-align: center;
  width: 20%;
}
.linkTransaction {
  text-align: center;
  width: 20%;
  cursor: pointer;
}
