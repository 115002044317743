.mapFilterAndSearch {
  position: absolute;
  left: 10px;
  top: 10px;
  display: flex;
  align-items: flex-start;
  gap: 5px;

  &Activator {
    background: white;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    border: none;
    outline: none;
    font-size: 0;

    &Icon {
      cursor: pointer;
    }
  }
}
