.headerRoot {
    .headerMenuButton {
        margin-right: 10px;
    }
    .headerTitle {
        flex-grow: 1;
    }
    .headerButton {
        margin-left: auto;
        text-transform: unset;
        font-size: 1rem;
    }
    .headerHeader {
        padding: 0 2.5rem;
    }
    .headerIcon {
        fill: #fff;
    }
    .headerSelectStyle {
        paddingLeft: 0.7rem;
    }
    .headerMenuItem {
        color: #3f51b5;
        font-weight: bold;
        width: 12rem;
        padding-left: 10%;
        font-size: 1rem;
    }
    .headerFormStyle {
        flex-direction: row;
        margin-left: auto;
    }
    .headerCityStyle {
        padding-top: 0.3rem;
        padding-left: 8.5rem;
        font-size: 1.2rem;
        font-weight: bold;
    }
    .headerSiteLogo {
        height: 2.2rem;
    }
    .headerSelectInput {
        border-radius: 4px;
        position: relative;
        font-size: 0.9rem;
        padding-top: 0.7rem;
    }
}
