.bikeId {
  text-align: center;
  width: 10%;
  height: 40px
}
.bikeCode {
  text-align: center;
  width: 17%;
}
.bikeType {
  text-align: center;
  width: 10%;
}
.bikeAssignedStation {
  text-align: center;
  width: 20%;
}
.bikeIsLock {
  text-align: center;
  width: 10%;
}
.bikeStatus {
  text-align: center;
  width: 15%;
}
.bikeAvailability {
  text-align: center;
  width: 8%;
}

.bikeLink {
  cursor: pointer;
}

.edit {
  border: 1px solid grey;
  border-radius: 5px;
  min-width: 80%;
  text-align: center;
}